<template>
  <div v-if="alert.display" class="root" @click="alert.display = false">
    <p :class="`c-alert ${type}`">
      <i class="fa-solid fa-circle-exclamation" />
      <span>{{ alert.message }}</span>
    </p>
  </div>
</template>

<script setup lang="ts">
const alert = useAlertStore()

const type = computed(() => {
  if (alert.type === 'warning') {
    return 'c-alert--warning'
  } else {
    return 'c-alert--primary'
  }
})

watch(alert, () => {
  setTimeout(() => {
    alert.display = false
  }, 3000)
})
</script>

<style lang="scss" scoped>
.root {
  z-index: $alert-z-index;
  position: fixed;
  top: 0;
  width: 100%;
  margin-top: $header-height;
  margin-bottom: -$header-height;

  @include media-sp() {
    margin-top: $sp-header-height;
    margin-bottom: -$sp-header-height;
  }
}
</style>
