<template>
  <div class="l-sidemenu">
    <div class="l-sidemenu-title" @click="onClick">
      <font-awesome-icon :icon="['fas', 'xmark']" />
    </div>
    <ul class="l-sidemenu-list">
      <li class="c-arrow--right">
        <NuxtLink to="/" active-class="active" @click="onClick">
          Gacha Online トップヘ
        </NuxtLink>
      </li>
      <li v-if="isLogin" class="c-arrow--right">
        <NuxtLink to="/shipping/pending" active-class="active" @click="onClick">
          引き換え可能
          <!-- 引き換え可能時のアイコン -->
          <font-awesome-icon
            v-if="isNotsendIcon"
            :icon="['fas', 'circle-exclamation']"
          />
        </NuxtLink>
      </li>
      <li v-if="isLogin" class="c-arrow--right">
        <NuxtLink
          to="/shipping/requested"
          active-class="active"
          @click="onClick"
        >
          配送依頼済み
        </NuxtLink>
      </li>
      <li v-if="isLogin" class="c-arrow--right">
        <NuxtLink to="/shipping/sent" active-class="active" @click="onClick">
          発送済み
        </NuxtLink>
      </li>
      <li v-if="isLogin" class="c-arrow--right">
        <NuxtLink to="/mypage/" active-class="active" @click="onClick">
          マイページ
        </NuxtLink>
      </li>
      <li v-if="!isLogin" class="c-arrow--right">
        <NuxtLink to="/auth/signup" active-class="active" @click="onClick">
          新規会員登録
        </NuxtLink>
      </li>
      <li class="c-arrow--right">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdYfrSHO6-1xF7kezFmtC6VAMzlaIuatZICrxSbXynk4ZIIIA/viewform"
          target="_blank"
          rel="noreferrer"
        >
          お問い合わせ
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const alert = useAlertStore()
const setting = useSettingStore()
const isLogin = computed(() => useUserStore().isLogin)
const isNotsendIcon = computed(() => useUserRewordStore().notsendCount > 0)

const onClick = () => {
  alert.display = false
  setting.isMenu = false
}
</script>

<style lang="scss" scoped>
.l-sidemenu {
  background-color: #fff;
  min-height: 100%;
  height: 100%;
  padding-bottom: 80px;
  @include media-pc {
    display: none;
  }

  &-title {
    padding: 20px 0;
    text-align: right;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    svg {
      @include animation;
      position: relative;
      border: 1px solid #c9ccd4;
      border-radius: 50%;
      padding: 5%;
      width: 14px;
      &::after {
        position: absolute;
        content: '◯';
        font-size: 46px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 200;
        color: #c9ccd4;
      }
      @include hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }

  &-subtitle {
    text-align: center;
    color: white;
  }

  &-heading {
    position: relative;
    padding: 32px 20px 18px 16px;
    color: #e7eef0;
    font-size: 1.6rem;

    &::before {
      content: '';
      position: absolute;
      left: 21px;
      bottom: -11px;

      $size: 10px;
      border-top: $size solid #000;
      border-right: $size solid transparent;
      border-left: $size solid transparent;
    }
  }

  &-list {
    & li {
      background-color: #fff;
      border-top: 1px solid #000;
      &:last-child {
        border-bottom: 1px solid #000;
      }

      &:hover,
      .active {
        background-color: #c6c6c6;
        color: black;

        & a {
          color: black;
        }
      }

      & a {
        display: block;
        padding: 20px 10px 20px 20px;
        color: #000;
        font-size: 14px;
        font-weight: 700;

        .svg-inline--fa.fa-circle-exclamation {
          color: #fa2f2f;
          font-size: 21px;
          position: absolute;
          right: 18%;
          top: 50%;
          translate: 50% -50%;
        }
      }
    }
  }
}
</style>
