<template>
  <div :style="loadingWrapStyle" class="loading">
    <div :style="loadingStyle" class="sk-chase">
      <div v-for="num in 6" :key="num" class="sk-chase-dot" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * ローディング
 */
export default defineComponent({
  name: 'LoadingComponent',
  props: {
    size: {
      type: Number,
      required: false,
      default: 100,
    },
    paddingY: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    /**
     * 指定されている場合はその値を使用。未指定の場合は、sizeの1/2をpadding-yに設定
     */
    computedPaddingY(): number {
      return this.paddingY === null ? this.size * 0.5 : this.paddingY
    },
    loadingStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      }
    },
    loadingWrapStyle() {
      return {
        width: `${this.size}px`,
        padding: `${this.computedPaddingY}px 0`,
      }
    },
  },
})
</script>

<style lang="scss" scoped>
// @see https://tobiasahlin.com/spinkit/
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: rgba($color, 0.7);
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
</style>

<style lang="scss" scoped>
.loading {
  margin: 0 auto;
}
</style>
