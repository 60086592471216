<template>
  <div v-if="isLoading || isInnerLoading" class="loading-page">
    <Loading :size="100" class="loading" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Loading from './Loading.vue'

/**
 * ページ全体に表示するローディング
 */
export default defineComponent({
  name: 'PageLoadingFullComponent',
  components: {
    Loading,
  },
  props: {
    // ローディングを行うか
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isInnerLoading: false,
      startTimeoutId: null,
    } as {
      isInnerLoading: boolean
      startTimeoutId: ReturnType<typeof setTimeout> | null
    }
  },
  methods: {
    // ローディング開始
    start() {
      this.startTimeoutId = setTimeout(() => {
        this.startTimeoutId = null
        this.isInnerLoading = true
      }, 100)
    },
    // ローディング停止
    finish() {
      if (this.startTimeoutId) {
        clearTimeout(this.startTimeoutId)
      }

      setTimeout(() => {
        this.isInnerLoading = false
      }, 100)
    },
  },
})
</script>

<style lang="scss" scoped>
.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-base + 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(#fff, 0.4);
}

.loading {
  margin-top: -100px;
  @include media-sp {
    margin-top: -60px;
  }
}
</style>
